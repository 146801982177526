import React, {lazy, useEffect, useState} from "react";
import Modal from "react-modal";
import Cookies from "js-cookie";
import {Helmet} from "react-helmet";
import {generateSessionId, isToday} from "./utils";
import {customStyles, customTriviaStyles} from "./customStyles";
import {GameEndpoints} from "../../consts/GameEndpoints";

const Spinner = lazy(() => import("../Spinner"));
const ShareModal = lazy(() => import("../ShareModal"));

const useProdEndpoints =
    process.env.ENVIRONMENT !== "dev" && process.env.ENVIRONMENT !== "test";

interface QuickfireEnabled {
    enabled: boolean;
    competitionsToLoad: string;
    countQuestions: number;
}

interface UserAccount {
    username: string;
    profile_pic: string;
}

export const Games = () => {
    const [title] = useState("Cricket8.com | Games");
    const [description] = useState(
        "Check this out - Grab your free coins before the launch of our " +
        "exciting new game! Join the Cricket8.com community today and start collecting your rewards!"
    );
    const hashtags = ["cricket", "play"];

    const [isSpinModalOpen, setIsSpinModalOpen] = useState(false);
    const [isQuickfireModalOpen, setIsQuickfireModalOpen] = useState(false);
    const [isWheelTriviaModalOpen, setIsWheelTriviaModalOpen] = useState(false);
    const [isShareModalOpen, setIsShareModalOpen] = useState(false);

    const openShareModal = () => setIsShareModalOpen(true);
    const closeShareModal = () => {
        getWallet();
        setIsShareModalOpen(false);
    };

    const [gameSessionId, setGameSessionId] = useState("");
    const [showWallet, setShowWallet] = useState(false);
    const [userAccount, setUserAccount] = useState<UserAccount>({
        username: "",
        profile_pic: "",
    });
    const [coins, setCoins] = useState();
    const [rubies, setRubies] = useState();
    const [spinPlayed, setSpinPlayed] = useState(false);
    const [quickfireEnabledToday, setQuickfireEnabledToday] =
        useState<QuickfireEnabled>({
            enabled: false,
            competitionsToLoad: "",
            countQuestions: 0,
        });
    const [quickfirePlayed, setQuickfirePlayed] = useState(false);
    const [wheelTriviaPlayed, setWheelTriviaPlayed] = useState(false);
    const [lastWonSpin, setLastWonSpin] = useState(0);
    const [lastWonQuickfire, setLastWonQuickfire] = useState(0);
    const [lastWonWheelTrivia, setLastWonWheelTrivia] = useState(0);
    const [counterSpin, setCounterSpin] = useState(false);
    const [counterQuickfire, setCounterQuickfire] = useState(false);
    const [counterWheelTrivia, setCounterWheelTrivia] = useState(false);
    const [loading, setLoading] = useState(true);
    const [quickfireAnsweredQuestions, setQuickfireAnsweredQuestions] =
        useState(0);
    const [quickfireMaxQuestions, setQuickfireMaxQuestions] =
        useState(20);
    const [maxQuestionsInAccountToday, setMaxQuestionsInAccountToday] =
        useState(false);
    const [shouldShowNumberOfQuestions, setShouldShowNumberOfQuestions] =
        useState(false);
    // const quickfireMaxQuestions = 20;
    const [isTraderEnabled, setIsTraderEnabled] = useState(false);
    const [isQuickfireEnabled, setIsQuickfireEnabled] = useState(false);
    const [isSquaresEnabled, setIsSquaresEnabled] = useState(true);

    const url = window.location.href;

    const startSpinGame = () => {
        setIsSpinModalOpen(true);
    };

    const startQuickfireGame = () => {
        setShouldShowNumberOfQuestions(false);
        setIsQuickfireModalOpen(true);
    };

    const startWheelTriviaGame = () => {
        setIsWheelTriviaModalOpen(true);
    };

    useEffect(() => {
        const updatePageTitle = () => {
            document.title = "Games | Cricket8";
        };
        updatePageTitle();

        return () => {
        };
    }, []);

    const SpinModal = ({isOpen, onRequestClose}) => {
        const spin_endpoint = useProdEndpoints
            ? GameEndpoints.SpinProd
            : GameEndpoints.SpinDev;

        useEffect(() => {
            const handleResize = () => {
            };
            window.addEventListener("resize", handleResize);
            return () => window.removeEventListener("resize", handleResize);
        }, []);

        useEffect(() => {
            const handleMessage = (event) => {
                if (event.data === "GAME_DONE" && event.origin === spin_endpoint) {
                    setIsSpinModalOpen(false);
                    setCounterSpin(true);
                    getSpinWinnings();
                }
            };

            window.addEventListener("message", handleMessage);
            return () => {
                window.removeEventListener("message", handleMessage);
            };
        }, []);

        return (
            <Modal
                id="Modalspinner"
                isOpen={isOpen}
                onRequestClose={onRequestClose}
                style={customStyles}
                contentLabel="Spinner"
                ariaHideApp={false}
            >
                <div className="absolute top-[10px]  right-[8px]">
                    <button
                        onClick={onRequestClose}
                        className="modal-close-button text-right"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="1em"
                            height="1em"
                            viewBox="0 0 24 24"
                        >
                            <path
                                fill="currentColor"
                                d="M6.4 19L5 17.6l5.6-5.6L5 6.4L6.4 5l5.6 5.6L17.6 5L19 6.4L13.4 12l5.6 5.6l-1.4 1.4l-5.6-5.6z"
                            />
                        </svg>
                    </button>
                </div>
                <div style={{width: "100%", height: "100%"}}>
                    <iframe
                        src={`${spin_endpoint}/?sessionId=${gameSessionId}`}
                        frameBorder="0"
                        style={{width: "100%", height: "100%"}}
                        className="border-0"
                    ></iframe>
                </div>
            </Modal>
        );
    };

    const WheelTriviaModal = ({isOpen, onRequestClose}) => {
        const session_token = Cookies.get("c8_session_token");
        const wheel_trivia_endpoint = useProdEndpoints
            ? GameEndpoints.WheelTriviaProd
            : GameEndpoints.WheelTriviaDev;

        useEffect(() => {
            const handleResize = () => {
            };
            window.addEventListener("resize", handleResize);
            return () => window.removeEventListener("resize", handleResize);
        }, []);

        useEffect(() => {
            const handleMessage = (event) => {
                if (
                    event.data.event === "GAME_DONE" &&
                    event.origin === wheel_trivia_endpoint
                ) {
                    if (event.data.payload.gamesession) {
                        setWheelTriviaPlayed(true);
                    }
                    setIsWheelTriviaModalOpen(false);
                    getWheelTriviaWinnings(event.data.payload);
                }
            };

            window.addEventListener("message", handleMessage);
            return () => {
                window.removeEventListener("message", handleMessage);
            };
        }, []);

        return (
            <Modal
                id="Trivia"
                isOpen={isOpen}
                onRequestClose={onRequestClose}
                style={customTriviaStyles}
                contentLabel="Trivia"
                ariaHideApp={false}
            >
                <div style={{width: "100%", height: "100%"}}>
                    <iframe
                        src={`${wheel_trivia_endpoint}/?sessionId=${session_token}
              &coins=${lastWonSpin}&rubies=${rubies}&username=${userAccount.username}`}
                        frameBorder="0"
                        style={{width: "100%", height: "100%"}}
                        className="border-0"
                    ></iframe>
                </div>
            </Modal>
        );
    };

    const QuickfireModal = ({
                                isOpen,
                                onRequestClose,
                                competitionsToLoad,
                                countQuestions,
                                maxQuestions,
                            }) => {
        const session_token = Cookies.get("c8_session_token");
        let quickfire_endpoint = useProdEndpoints
            ? GameEndpoints.QuickfireProd
            : GameEndpoints.QuickfireDev;

        useEffect(() => {
            const handleResize = () => {
            };
            window.addEventListener("resize", handleResize);
            return () => window.removeEventListener("resize", handleResize);
        }, []);

        useEffect(() => {
            const handleMessage = (event) => {
                if (
                    event.data.event === "GAME_DONE" &&
                    event.origin === quickfire_endpoint
                ) {
                    setQuickfirePlayed(true);
                    setCounterQuickfire(true);
                    setIsQuickfireModalOpen(false);
                    getQuickfireWinnings(event.data.payload, maxQuestions);
                }
            };

            window.addEventListener("message", handleMessage);
            return () => {
                window.removeEventListener("message", handleMessage);
            };
        }, []);

        return (
            <Modal
                id="Quickfire"
                isOpen={isOpen}
                onRequestClose={onRequestClose}
                style={customTriviaStyles}
                contentLabel="Quickfire"
                ariaHideApp={false}
            >
                <div style={{width: "100%", height: "100%"}}>
                    <iframe
                        src={`${quickfire_endpoint}/?sessionId=${session_token}&coins=${coins}&rubies=${rubies}&username=${userAccount.username}&competition=${competitionsToLoad}&countQuestions=${countQuestions}`}
                        frameBorder="0"
                        style={{width: "100%", height: "100%"}}
                        className="border-0"
                    ></iframe>
                </div>
            </Modal>
        );
    };

    const getWheelTriviaWinnings = async (payload) => {
        const session_token = Cookies.get("c8_session_token");
        if (session_token && gameSessionId !== "") {
            if (!payload || payload.length == 0) {
                return;
            }
            if (!payload.gamesession || payload.gamesession === "") {
                return;
            }
            const response = await fetch(
                `${process.env.API_URL}/triviaendgame/${session_token}`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "x-api-key": process.env.API_KEY as string,
                    },
                }
            );
            if (response.status === 200) {
                await getWallet();
            }
        }
    };

    const getQuickfireWinnings = async (payload, maxQuestions) => {
        const session_token = Cookies.get("c8_session_token");
        if (!session_token) {
            window.location.href =
                "/signup?gamesession=" +
                payload.gamesession +
                "&game=quickfire-trivia" +
                "&coins=" +
                payload.coins +
                "&back=games";
            return;
        }
        if (session_token && gameSessionId !== "") {
            const response = await fetch(
                `${process.env.API_URL}/quickfireendgame/${session_token}`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "x-api-key": process.env.API_KEY as string,
                    },
                    body: JSON.stringify({
                        game_session: payload.gamesession,
                        coins: payload.coins,
                        max_questions: maxQuestions
                    }),
                }
            );
            if (response.status === 200) {
                await getWallet();
            }
        }
    };

    const getSpinWinnings = async () => {
        const session_token = Cookies.get("c8_session_token");
        if (!session_token) {
            window.location.href =
                "/signup?gamesession=" + gameSessionId + "&game=wheel-spin&back=games";
            return;
        }
        setSpinPlayed(true);
        const response = await fetch(
            `${process.env.API_URL}/collectwinnings/${gameSessionId}/${session_token}`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "x-api-key": process.env.API_KEY as string,
                },
            }
        );
        if (response.status === 200) {
            await getWallet();
            if (!wheelTriviaPlayed) {
                startWheelTriviaGame();
            }
        }
    };

    const getWallet = async () => {
        const session_token = Cookies.get("c8_session_token");
        if (!session_token) {
            setLoading(false);
            return;
        }
        const response = await fetch(
            `${process.env.API_URL}/account/${session_token}`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "x-api-key": process.env.API_KEY as string,
                },
            }
        );
        setLoading(false);
        if (response.status === 200) {
            let lastWonSpin = 0;
            let lastWonQuickfire = 0;
            let lastWonWheelTrivia = 0;
            const data = await response.json();
            setUserAccount({
                username: data.data.username,
                profile_pic: data.data.profile_pic,
            });
            if (data.data.games !== null) {
                for (let i = 0; i < data.data.games.length; i++) {
                    if (data.data.games[i].name === "wheel-spin") {
                        if (isToday(data.data.games[i].values.last)) {
                            setSpinPlayed(true);
                            lastWonSpin += data.data.games[i].values.lastwon;
                            setCounterSpin(false);
                        }
                    }
                    if (data.data.games[i].name === "wheel-trivia") {
                        if (isToday(data.data.games[i].values.last)) {
                            lastWonWheelTrivia += data.data.games[i].values.lastwon;
                            if (data.data.games[i].values.played > 0) {
                                setWheelTriviaPlayed(true);
                            } else {
                                setWheelTriviaPlayed(false);
                            }
                            setCounterWheelTrivia(false);
                        }
                    }
                    if (data.data.games[i].name === "quickfire-trivia") {
                        if (isToday(data.data.games[i].values.last)) {
                            lastWonQuickfire += data.data.games[i].values.lastwon;
                            setQuickfirePlayed(true);
                            setCounterQuickfire(false);
                            setQuickfireAnsweredQuestions(data.data.games[i].values.answered);
                            if (data.data.games[i].values.questionstoanswer) {
                                setQuickfireMaxQuestions(data.data.games[i].values.questionstoanswer)
                                setMaxQuestionsInAccountToday(true);
                            }
                            setShouldShowNumberOfQuestions(true);
                        } else {
                            if (quickfireEnabledToday && quickfireEnabledToday.enabled) {
                                setQuickfireMaxQuestions(quickfireEnabledToday.countQuestions);
                            }
                        }
                    }
                }

                setLastWonSpin(lastWonSpin);
                setLastWonQuickfire(lastWonQuickfire);
                setLastWonWheelTrivia(lastWonWheelTrivia);
            }
            if (data?.data?.wallet) {
                setCoins(data.data.wallet?.coins || 0);
                setRubies(data.data.wallet?.rubies || 0);
            }
            setShowWallet(true);
        }
    };

    const areGamesEnabled = async () => {
        const response = await fetch(`${process.env.API_URL}/isgameenabled/ALL`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "x-api-key": process.env.API_KEY as string,
            },
        });
        if (response.status !== 200) {
            return;
        }
        const data = await response.json();
        const traderIndex = data.findIndex((x) => x.game_name === "Trader");
        if (traderIndex > -1) {
            setIsTraderEnabled(data[traderIndex].is_enabled);
        }
        const squaresIndex = data.findIndex((x) => x.game_name === "Squares");
        if (squaresIndex > -1) {
            setIsSquaresEnabled(data[squaresIndex].is_enabled);
        }
        const quickfireIndex = data.findIndex((x) => x.game_name === "Quickfire");
        if (quickfireIndex > -1) {
            setIsQuickfireEnabled(data[quickfireIndex].is_enabled);
        }
    };

    const isQuickFireEnabled = async () => {
        const clientTimezoneName = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const date = new Date();
        const clientDate = date.toISOString().split("T")[0];
        const response = await fetch(
            `${process.env.API_URL}/quickfire/enabled?clientTZ=${clientTimezoneName}&clientDate=${clientDate}`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "x-api-key": process.env.API_KEY as string,
                },
            }
        );
        if (response.status === 200) {
            const res = await response.json();
            if (res.enabled && res.countQuestions > 0) {
                setQuickfireEnabledToday(res);
                if (res.countQuestions > quickfireMaxQuestions) {
                    res.countQuestions = quickfireMaxQuestions;
                }
                if (!maxQuestionsInAccountToday) {
                    setQuickfireMaxQuestions(res.countQuestions);
                }
            }
        }
    };

    useEffect(() => {
        setGameSessionId(generateSessionId(32));
        areGamesEnabled();
        isQuickFireEnabled().then(() => {
            getWallet();
        })
    }, []);

    return (
        <div>
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={description}/>
                <meta property="og:title" content={title}/>
                <meta property="og:description" content={description}/>
                <meta property="og:type" content="website"/>
                <meta property="og:url" content={window.location.href}/>
                <meta
                    property="og:image"
                    content={`https://${window.location.hostname}/img/spinner_large.png`}
                />
            </Helmet>

            <div
                id="content"
                className="bg-linear-gradient-games min-h-[50vh] mt-[0px] w-full content-t"
            >
                {loading ? null : (
                    <>
                        <div className="w-full flex flex-col justify-center items-center lg:flex">
                            <div
                                className="h-[70px] lg:h-[120px] bg-main w-full flex justify-between items-start font-anton text-white">
                                <div
                                    className="w-full h-full flex justify-center md:justify-start lg:justify-start items-center ">
                                    <div>
                                        <p className="px-2 lg:px-14 text-[30px] lg:text-[50px]">
                                            <span className="text-lime">PLAY</span> Cricket8 games
                                        </p>
                                    </div>
                                </div>
                                <div className="hidden md:flex lg:flex h-full mr-4">
                                    {Cookies.get("c8_session_token") && (
                                        <div className="w-full flex justify-center items-center">
                                            <div>
                                                <button
                                                    className="w-[45px] h-[45px] flex justify-center items-center ml-[10px]"
                                                    style={{border: "1px solid #D2FF00"}}
                                                    onClick={openShareModal}
                                                >
                                                    <img src="../img/public/Union.png"/>
                                                </button>
                                            </div>
                                            <ShareModal
                                                isOpen={isShareModalOpen}
                                                onRequestClose={closeShareModal}
                                                url={url}
                                                sharename="GAME PLAY"
                                                hashtags={hashtags}
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="w-full">
                                <div className="w-full px-2 lg:px-12">
                                    {spinPlayed && !wheelTriviaPlayed ? (
                                        <div className="relative">
                                            <div className="absolute right-0 bottom-[-47px]">
                                                <button
                                                    className="w-[142px] lg:w-[180px] bg-red text-white font-bold font-anton
                          h-[40px] uppercase text-[12px] lg:text-[16px]"
                                                    onClick={() => startWheelTriviaGame()}
                                                >
                                                    Multiply your winnings
                                                </button>
                                            </div>
                                        </div>
                                    ) : null}
                                    <div
                                        className="flex flex-row justify-center items-center gap-2 text-white text-[30px]">
                                        <div
                                            className="w-full my-[40px] flex flex-row justify-between items-center bg-mainF h-[80px]
                        font-anton text-lime text-[20px] md:text-[27px] lg:text-[36px] uppercase"
                                        >
                                            <div className="lg:ml-[-45px]">
                                                <img
                                                    className="w-[97px] h-[97px] lg:w-[200px] lg:h-[200px]"
                                                    src="../img/public/spinner5.png"
                                                />
                                            </div>
                                            <div className="text-[16px] lg:text-[30px]">
                                                {spinPlayed ? (
                                                    <div className="flex flex-col justify-center items-center">
                                                        <span>Today’s spin winnings</span>
                                                        <span
                                                            className="font-chakra text-[12px] text-white normal-case">
                              Spin again tomorrow!
                            </span>
                                                    </div>
                                                ) : (
                                                    <span>SPIN daily for free coins</span>
                                                )}
                                            </div>

                                            {spinPlayed ? (
                                                <div
                                                    className="mr-2 px-2 flex justify-center items-center bg-white text-main">
                                                    <div>
                                                        <img
                                                            src={`${process.env.CDN_ENDPOINT}/assets/single_coin.png`}
                                                            className="h-[40px]"
                                                        />
                                                    </div>
                                                    {counterSpin ? null : (
                                                        <>{lastWonSpin + lastWonWheelTrivia}</>
                                                    )}
                                                </div>
                                            ) : (
                                                <div
                                                    className="mr-4 w-[60px] lg:w-[91px] h-[40px] bg-lime flex justify-center items-center
                                hover:bg-limehover cursor-pointer text-center"
                                                >
                                                    <button
                                                        className="font-anton w-full h-full text-main uppercase text-[16px]"
                                                        onClick={() => startSpinGame()}
                                                    >
                                                        SPIN
                                                    </button>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="grid w-full items-center gap-2 p-2 lg:px-12 sm:grid-cols-2 lg:flex">
                                {isQuickfireEnabled && quickfireEnabledToday.enabled && (
                                    <div
                                        className="bg-mainB flex flex-col justify-start items-center mt-2 w-full h-[266px] p-2">
                                        <div
                                            style={{
                                                backgroundImage: "url('/img/public/quickfire2.png')",
                                            }}
                                            className=" w-full h-[120px] p-2 flex flex-row justify-between"
                                        >
                                            <div>
                                                <img src="../img/public/quickfire.png"/>
                                                <p className="font-anton text-lime text-[30px] mt-2">
                                                    Quickfire{" "}
                                                </p>
                                            </div>
                                            <div className="mr-[-15px] m-[-40px]">
                                                <img src="../img/public/quickfire3.png"/>
                                            </div>
                                        </div>
                                        {!quickfirePlayed ? (
                                            <div
                                                className="h-[60px] mt-2 text-[14px]  font-chakra text-white normal-case leading-[20px] font-normal tracking-[0]">
                                                Play against the Games Master and test your domestic and
                                                international cricket knowledge.
                                            </div>
                                        ) : (
                                            <div
                                                className="w-full h-[60px] mt-2 text-[14px]  font-chakra text-white normal-case
                    leading-[20px] font-normal tracking-[0] flex justify-between"
                                            >
                                                <div
                                                    className="w-full text-brightgreen text-[18px] font-anton flex items-center">
                                                    <div
                                                        className="w-[30px] h-[30px] bg-brightgreen text-main text-[20px] p-[6px] flex items-center justify-center mr-2">
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="1em"
                                                            height="1em"
                                                            viewBox="0 0 20 20"
                                                        >
                                                            <path
                                                                fill="currentColor"
                                                                d="M8.294 16.998c-.435 0-.847-.203-1.111-.553L3.61 11.724a1.39 1.39 0 0 1 .27-1.951a1.39 1.39 0 0 1 1.953.27l2.351 3.104l5.911-9.492a1.396 1.396 0 0 1 1.921-.445c.653.406.854 1.266.446 1.92L9.478 16.34a1.39 1.39 0 0 1-1.12.656z"
                                                            />
                                                        </svg>
                                                    </div>
                                                    <div>
                                                        {shouldShowNumberOfQuestions && (
                                                            <span>
                                                                {quickfireAnsweredQuestions}/{quickfireMaxQuestions} Today
                                                            </span>
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="w-full flex flex-row justify-end items-center">
                                                    <div className="text-white text-[30px] font-anton">
                                                        {counterQuickfire ? null : <>+{lastWonQuickfire}</>}
                                                    </div>
                                                    <div>
                                                        <img
                                                            src={`${process.env.CDN_ENDPOINT}/assets/single_coin.png`}
                                                            className="h-[40px]"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        {!quickfirePlayed ? (
                                            <button
                                                onClick={startQuickfireGame}
                                                className="mt-2 flex justify-center items-center w-full h-[40px] text-main font-anton text-[16px] bg-lime text-center"
                                            >
                                                PLay quickfire qs
                                            </button>
                                        ) : quickfireAnsweredQuestions >=
                                        quickfireEnabledToday.countQuestions ? (
                                            <div
                                                className="mt-2 flex justify-center items-center w-full h-[40px] text-main font-anton text-[16px] bg-white text-center">
                                                Already Played
                                            </div>
                                        ) : (
                                            <button
                                                className="w-full mt-2 bg-lime font-anton text-main uppercase text-[16px] hover:bg-limehover flex justify-center items-center h-[40px] cursor-pointer text-center"
                                                onClick={startQuickfireGame}
                                            >
                                                Answer Another Quickfire Question
                                            </button>
                                        )}
                                    </div>
                                )}

                                {isTraderEnabled && (
                                    <div
                                        className="bg-mainB flex flex-col justify-start items-center mt-2 w-full h-[266px] p-2 opacity-70">
                                        <div
                                            style={{
                                                backgroundImage: "url('/img/public/trade1.png')",
                                            }}
                                            className=" w-full h-[120px] p-2  flex flex-row justify-between"
                                        >
                                            <div>
                                                <img src="../img/public/group.png"/>
                                                <p className="font-anton text-lime text-[30px] mt-2">
                                                    Trader
                                                </p>
                                            </div>
                                            <div className="mr-[-20px] mt-[-45px]">
                                                <img src="../img/public/trader2.png"/>
                                            </div>
                                        </div>
                                        <div
                                            className="mt-2 text-[14px] h-[60px] font-chakra text-white normal-case leading-[20px] font-normal tracking-[0]">
                                            Trade live matches on winner, run lines, fancies and more.
                                            Find your edge and press for profit.
                                        </div>
                                        {/*<Link className="w-full pointer-events: none" to="/trader">*/}
                                        {/*    <div*/}
                                        {/*        className="mt-2 flex justify-center items-center w-full h-[40px] text-main font-anton text-[16px] bg-lime text-center">*/}
                                        {/*        PLAY TRADER*/}
                                        {/*    </div>*/}
                                        {/*</Link>*/}

                                        {/*TODO: added for this release only, update with the code above*/}
                                        <div
                                            className="mt-2 flex justify-center items-center w-full h-[40px] text-main font-anton text-[16px] bg-white text-center">
                                            COMING SOON
                                        </div>
                                    </div>
                                )}

                                <div
                                    className={`md:hidden bg-main p-1 absolute right-[-55px] top-[455px] rotate-90 ${
                                        !Cookies.get("c8_session_token") ? "hidden" : ""
                                    }`}
                                >
                                    {Cookies.get("c8_session_token") && (
                                        <div className="w-full flex justify-center items-center">
                                            <div className="text-lime">Share for coins</div>
                                            <div>
                                                <button
                                                    className="w-[30px] h-[30px] bg-lime text-main flex justify-center items-center ml-[10px]"
                                                    onClick={openShareModal}
                                                >
                                                    <img src="../img/public/share.png"/>
                                                </button>
                                            </div>
                                            <ShareModal
                                                isOpen={isShareModalOpen}
                                                onRequestClose={closeShareModal}
                                                url={url}
                                                sharename="GAME PLAY"
                                                hashtags={hashtags}
                                            />
                                        </div>
                                    )}
                                </div>

                                {isSquaresEnabled && (
                                    <div
                                        className="bg-mainB flex flex-col justify-start items-center mt-2 w-full h-[266px] p-2 opacity-70">
                                        <div
                                            style={{
                                                backgroundImage: "url('/img/public/squares2.png')",
                                            }}
                                            className=" w-full h-[120px] p-2 flex flex-row justify-between"
                                        >
                                            <div>
                                                <img src="../img/public/squares.png"/>
                                                <p className="font-anton text-lime text-[30px] mt-2">
                                                    SQUARES
                                                </p>
                                            </div>

                                            <div className="mr-[-20px] m-[-10px]">
                                                <img src="../img/public/squares3.png"/>
                                            </div>
                                        </div>
                                        <div
                                            className="h-[60px] mt-2 text-[14px] font-chakra text-white normal-case leading-[20px] font-normal tracking-[0]">
                                            Live match ball-by-ball prediction for every over. Vs
                                            friends and pros.
                                        </div>
                                        {/*<Link className="w-full" to="/squares">*/}
                                        {/*    <div*/}
                                        {/*        className="mt-2 flex justify-center items-center w-full h-[40px] text-main font-anton text-[16px] bg-lime text-center">*/}
                                        {/*        PLAY SQUARES*/}
                                        {/*    </div>*/}
                                        {/*</Link>*/}
                                        <div
                                            className="mt-2 flex justify-center items-center w-full h-[40px] text-main font-anton text-[16px] bg-white text-center">
                                            COMING SOON
                                        </div>
                                    </div>
                                )}

                                <div
                                    className="md:hidden lg:hidden flex justify-start items-center text-start px-2 mt-4 bg-mainE h-[68px]">
                                    <p className="text-[13px] font-chakra normal-case leading-[100%]">
                                        <strong>Play for fun:</strong> Cricket8 is not a gambling
                                        company and you cannot win real money.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <SpinModal
                            isOpen={isSpinModalOpen}
                            onRequestClose={() => setIsSpinModalOpen(false)}
                        />
                        <WheelTriviaModal
                            isOpen={isWheelTriviaModalOpen}
                            onRequestClose={() => setIsWheelTriviaModalOpen(false)}
                        />
                        <QuickfireModal
                            isOpen={isQuickfireModalOpen}
                            onRequestClose={() => setIsQuickfireModalOpen(false)}
                            competitionsToLoad={quickfireEnabledToday.competitionsToLoad}
                            countQuestions={quickfireEnabledToday.countQuestions}
                            maxQuestions={quickfireMaxQuestions}
                        />
                    </>
                )}
            </div>
        </div>
    );
};
export default Games;
