export const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "0",
        width: "calc(100vw - 40px)",
        height: "calc(-40px + 100vh)",
        background: "none",
        textAlign: "center",
        padding: "0",
        position: "absolute",
        maxWidth: "476px",
        maxHeight: "690px",
        transform: "translate(-50%, -50%)",
        border: "none",
        borderRadius: "0",
    },
    overlay: {
        background: "rgba(11, 2, 18, 0.75)",
    },
};
export const customTriviaStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "0",
        width: "calc(100vw - 40px)",
        height: "calc(100vh - 100px)",
        background: "none",
        textAlign: "center",
        padding: "0",
        position: "absolute",
        maxWidth: "476px",
        maxHeight: "700px",
        transform: "translate(-50%, -50%)",
        border: "none",
        borderRadius: "0",
    },
    overlay: {
        background: "rgba(11, 2, 18, 0.75)",
    },
};
